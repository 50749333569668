import React, { useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { LockOutlined, UserOutlined, SmileOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Space, notification } from 'antd'
import { captcha,checkCode,checkAccount,checkPassword } from '../../../utils/validator'
import Logo from '../../../components/Logo'
import './index.scss'
import api from '../../../https/api'

export default function Login() {
  const navigate = useNavigate()
  const [code,setCode] = useState('')
  useEffect(() => {
    setCode(captcha())
  },[])

  const onFinish = async (values) => {
    values.account = btoa(values.account)
    values.password = btoa(values.password)
    const {data:{code,data,msg}} = await api.accountLogin(values)
    if(code === -1){
      setCode(captcha())
      return message.error(msg)
    }

    message.success(msg)
    if(!data.pwd_status){
      notification.warning({
        message: '温馨提示',
        description:'请及时修改初始密码，谨防账号泄露风险',
        placement:'topLeft',
        duration:null
      })
    }
    localStorage.setItem('crm_manage_account',JSON.stringify(data))
    navigate('/index')
  }
  return (
      <div className='login'>
        <Logo/>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="account"
            rules={[
              {required: true,message: '请输入账号'},
              {validator:checkAccount}
            ]}
          >
            <Input 
              prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="账号"
              autoComplete='off'
            />
          </Form.Item>
           <Form.Item
            name="password"
            rules={[
              {required: true,message: '请输入密码'},
              {validator:checkPassword}
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item className='captcha'>
            <Space>
              <Form.Item 
                hasFeedback
                name="code" 
                rules={[
                  {required: true,message: '请输入验证码'},
                  {validator:checkCode}
                ]} 
              >
                <Input 
                  prefix={<SmileOutlined className="site-form-item-icon" />} 
                  maxLength={5} 
                  placeholder='验证码'
                  autoComplete='off'
                >
                </Input>
              </Form.Item>
              <img 
                className='img' 
                src={code} 
                alt='captcha'
                onClick={()=>setCode(captcha())}
                 />
            </Space>
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>自动登录</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
  )
}
