import React ,{ useCallback, useContext, useEffect, useState } from 'react'
import { Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { crmManageAccount } from '../../utils/utils'
import MyContext from '../../context'
import api from '../../https/api'
const { role } = crmManageAccount()
export default function MySider() {
  const { menuPid } = useContext(MyContext)
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const [current,setCurrent] = useState(null)
  const [menus,setMenus] = useState([])

  const getSiderMenus = useCallback( async () => {
    if(menuPid){
      const { data:{data} } = await api.getMenus({pid:menuPid,role})
      if(pathname.substring(1).split('/').length === 1){
        navigate(data[0].key)
      }
      setMenus(menus => {
        return data.map(item => {
          if(pathname.includes(item.key)){
            setCurrent(item.key)
          }
          item.icon = item.icon ? <i className={`iconfont ${item.icon}`} style={{fontSize:'16px'}}></i> : null
          return item
        })
      })
    }
  },[menuPid,pathname])
  useEffect(()=>{
    getSiderMenus()
  },[getSiderMenus])

  return (
    <Menu
      mode="inline"
      items={menus}
      selectedKeys={current}
      openKeys={current}
      onClick={({key, keyPath}) => {
        navigate(key)
        setCurrent(key)
      }}
    /> 

  )
}
