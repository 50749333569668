import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal,Button } from 'antd'
import { LogoutOutlined ,ExclamationCircleOutlined} from '@ant-design/icons'
import { crmManageAccount } from '../../utils/utils'
export default function Logout() {
  const navigate = useNavigate()
  const { account } = crmManageAccount()
  const [modal, contextHolder] = Modal.useModal();
  const logout = () => {
    modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定要退出吗？',
      okText: '确认',
      cancelText: '取消',
      onOk:() =>{
        localStorage.removeItem('crm_manage_account')
        navigate('/login')
      }
    });
  }
  return (
    <div className='userinfo'>
      <span>账号:{account}</span>
      <Button type="link" onClick={logout}><LogoutOutlined />退出</Button>
      {contextHolder}
    </div>
  )
}
