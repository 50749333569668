import api from "../https/api"
const checkAccount = (rule,value) => {
  if(/^[\d\w]{5,15}$/.test(value)){
    return Promise.resolve()
  }
  return Promise.reject('5-15位英文或数字')
}

const checkPassword = (rule,value) => {
  if(value && (value.length < 8 || value.length > 15)){
    return Promise.reject('密码长度8-15位')
  }
  if(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!#@*&.])[a-zA-Z\d!#@*&.]*$/.test(value)){
    return Promise.resolve()
  }
  return Promise.reject('密码须包含大小写英文、数字、字符(!#@*&.)')
}

const checkCode = async (rule,value) => {
  if(!value){
    return Promise.reject()
  }
  if(value.length < 5){
    return Promise.reject('验证码长度5位')
  }

  const { data } = await api.checkCaptcha({code:value})
  if(data.code !== 0){
    return Promise.reject(data.msg)
  }
  return Promise.resolve()
}

const checkPhone = async (rule,value) => {

  if(!value){
    return Promise.reject()
  }
  
  if(!/^1[3456789]\d{9}$/.test(value)){
    return Promise.reject('手机号码格式错误')
  }

  return Promise.resolve()
}
const captcha = () => {
  return `/manage/login/code?random=${Math.random()}`
}

const checkMoney = (rule,value) => {

  if(!value){
    return Promise.resolve()
  }
  if(!/^[1-9][0-9.]{2,9}$/.test(value)){
    return Promise.reject('金额格式不正确')
  }
  return Promise.resolve()
}

const checkAnswer = (rule,value) => {
  if(!/^[A-Z]+$/.test(value)){
    return Promise.reject('答案必须是大写英文字母')
  }
  if(/(\w)\1/.test(value)){
    return Promise.reject('不能出现重复字符')
  }
  return Promise.resolve()
}
const checkExamOptions = (rule,value) => {
  if (value.length < 2) {
    return Promise.reject(new Error('至少两个选项'));
  }
  return Promise.resolve()
}
export {
  checkAccount,
  checkPassword,
  checkCode,
  captcha,
  checkPhone,
  checkMoney,
  checkAnswer,
  checkExamOptions,
}