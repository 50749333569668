import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
const root = ReactDOM.createRoot(document.getElementById('root'));
message.config({
  duration:2,
  maxCount:3,
})
dayjs.locale('cn')
root.render(
  <BrowserRouter>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </BrowserRouter>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);