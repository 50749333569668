import { Suspense,useEffect,useState } from 'react'
import { useRoutes,useNavigate, useLocation } from 'react-router-dom'
import { Layout,Skeleton} from 'antd'
import { isLogin } from './utils/utils'
import MyHeader from './components/MyHeader'
import MySider from './components/MySider'
import MyContext from './context'
import routes from './routes'
import './App.css'
import dayjs from 'dayjs'
const { Sider, Content,Footer } = Layout
const footerStyle = {
  textAlign: 'center',
  borderTop: '1px solid #D7D8D9',
}
function App() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [menuPid, setMenuPid] = useState('')
  const contentStyle = {
    padding:'10px',
    background: pathname.includes('login') ? '#fff' : ''
  }
  useEffect(() => {
    if(!isLogin()){
      navigate('/login')
    }else{
      if(pathname === '/'){
        return setMenuPid(1)
      }
      navigate(pathname)
    }
    /* const handleBeforeUnload = (event) => {
      localStorage.removeItem('crm_manage_account')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    } */
  },[])
  return (
    <MyContext.Provider value={{menuPid, setMenuPid}}>
      <Layout className='App'>
          {pathname.includes('login') ? null : <MyHeader/> }
          <Layout hasSider>
            {pathname.includes('login') ? null : <Sider><MySider/></Sider> }
            <Content style={contentStyle}>
              <Suspense fallback={ <Skeleton />}>
                {useRoutes(routes) }
              </Suspense>
            </Content>
          </Layout>
          <Footer style={footerStyle}>Copyright © 2016-{dayjs().format('YYYY')} 意数信息技术(上海)有限公司</Footer>
        </Layout>
    </MyContext.Provider>
  )
}
export default App;
