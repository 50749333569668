import axios from "axios"
import { message } from 'antd'
import { crmManageAccount } from '../utils/utils'
axios.interceptors.request.use((config) => {
  const { token,corp,id,account } = crmManageAccount()

  config.headers = {
    ...config.headers,
    'Content-Type':'multipart/form-data',
    token,
    corp,
    account,
    id
  }

  return config
}) 

//响应处理
axios.interceptors.response.use(config => {
  if(config.data.code === -100){
    message.error('登录令牌失效，即将退出平台，请重新登录')
    localStorage.removeItem('crm_manage_account')
    setTimeout(()=>{
      return window.location.href = '/login'
    },3000)
  }
  return config
})


const http = {
  post:'',
  get:'',
  put:'',
  del:''
}

http.post = function(api,data){
  return new Promise((resolve, reject) => {
    axios.post(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}

http.get = function(api,data){
  return new Promise((resolve, reject) => {
    axios.get(api,{params:data}).then(renponse => {
      resolve(renponse)
    })
  })
}

http.put = function(api,data){
  return new Promise((resolve, reject) => {
    axios.put(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}

http.delete = function(api,data){
  return new Promise((resolve, reject) => {
    axios.delete(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}
http.patch = function(api,data){
  return new Promise((resolve, reject) => {
    axios.patch(api,data).then(renponse => {
      resolve(renponse)
    })
  })
}
export default http