//const BASE_URL = 'http://crm.com'
//const BASE_URL = 'https://http://manage.yishush.net'
const BASE_URL = 'https://manage.yishush.cn'
const WEB_URL = 'https://client.yishush.cn'
const MINIPROGRAM_URL = 'https://miniprogram.yishush.cn'

export {
  BASE_URL,
  WEB_URL,
  MINIPROGRAM_URL
}