import React from 'react'
import { Outlet } from 'react-router-dom'

export default function Product() {
  return (
    <div className='product'>
      <Outlet/>
    </div>
  )
}
